import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyles, ThemeProvider } from '@electricjs/arc';
import { App } from './components';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
