import React, { useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Pane, Text } from '@electricjs/arc';

import { getDataTokenFromURL } from '../../helpers/getDataTokenFromURL';
import Logo from './Logo.svg';

const GradientPane = styled(Pane)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
      107.56deg,
      rgba(255, 0, 102, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 41.15%,
      rgba(0, 0, 0, 0.4) 60.42%,
      rgba(24, 191, 255, 0.4) 100%
    ),
    #000;
`;

const StyledSVG = styled(SVG)`
  align-self: center;
`;

export function App() {
  useEffect(() => {
    const dataToken = getDataTokenFromURL();
    const CUSTOM_PROTOCOL = 'electric-app';

    if (dataToken) {
      window.location.href = `${CUSTOM_PROTOCOL}://register-device?dt=${dataToken}`;
    }
  }, []);

  return (
    <GradientPane
      className="gradient"
      borderRadius={0}
      justifyContent="center"
      flexDirection="column">
      <Pane
        backgroundColor="#fff"
        flexDirection="column"
        width="490px"
        marginLeft="auto"
        marginRight="auto"
        padding="24px 34px 40px 34px">
        <StyledSVG src={Logo} />
        <Text variant="heading" fontSize="2.4rem" mt={3}>
          Registering your device
        </Text>
        <Text variant="subheading" mt={4}>
          Click{' '}
          <Text variant="subheading" tag="span" fontWeight="500">
            Open Electric
          </Text>{' '}
          on the dialog shown by your browser. If you don't see a dialog, reach
          out to{' '}
          <Text variant="subheading" tag="span" fontWeight="500">
            Electric for support
          </Text>
          .
        </Text>
      </Pane>
    </GradientPane>
  );
}
